import React from "react"
import { Link } from "gatsby"

const Footer = () => {
    return (
        <footer className="pt-5">

            <div className="container pb-3">
                <div className="row">
                    <div className="col-12 col-md">
                        <img src="/img/logo-small.jpeg" alt=""></img>
                        <p className="paragraph text-white">Head office: No: 30, Ohm Vinayaka Street, Thanneer Pandal, V. K. Road, Peelamedu, Coimbatore – 641004 India</p><br />
                        <p><a href="mailto:sales@innoway.in">sales@innoway.in</a></p><br />
                        <p className="paragraph text-white">Mobile: +91 890 304 0044<br></br>Landline: +91 422 2513247</p>
                    </div>

                    <div className="col-6 col-md">
                        <h5 className="head">Products</h5>
                        <ul className="list-unstyled text-small">
                            <li className="">
                                <Link to="/products/">
                                    <span className="">SPM Assemblies</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/products/">
                                    <span className="">Pneumatic Presses</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/products/">
                                    <span className="">Hydro-Pneumatic presses</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/products/">
                                    <span className="">Assembly Jig &amp; Fixtures</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/products/">
                                    <span className="">Leak testers -Customized</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/products/">
                                    <span className="">Material Handling trolleys</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/products/">
                                    <span className="">Work stations</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/products/">
                                    <span className="">Testing equipments</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/products/">
                                    <span className="">Reliability testing units</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/products/">
                                    <span className="">Fully automated work stations</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-md">
                        <h5 className="head">Services</h5>
                        <ul className="list-unstyled text-small">
                            <li className="">
                                <Link to="/services/3d-modeling/"> <span className="">3D-MODELING</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/services/cad-conversion/"> <span className="">CAD CONVERSION</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/services/2d-drafting/"> <span className="">2D-DRAFTING &amp; DETAILING</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/services/3d-rendering/"> <span className="">3D RENDERING</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/services/mold-design/"> <span className="">MOLD DESIGN</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/services/simulations/"> <span className="">SIMULATIONS</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-md">
                        <h5 className="head">Company</h5>
                        <ul className="list-unstyled text-small">
                            <li className="">
                                <Link to="/aboutus/"> <span className="">About Us</span>
                                </Link>
                            </li>
                            <li className="">
                                <Link to="/contactus/"> <span className="">Contact us</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="copyright container py-3 col-12 col-md">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <p className="paragraph text-white">Copyright © 2020 Innoway Systems</p>
                    </div>
                    <div className="col-12 col-lg-8">
                        <p className="paragraph text-white text-right">Powered by Innoway Systems</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
