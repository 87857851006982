import React from "react"
import { Link } from "gatsby"

const Header = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-lights">
        <a className="navbar-brand d-flex" href="/">
          <img src="/img/logo-small.jpeg" className="test" alt="innoway"></img>
          <div>
            <p className="site"> INNOWAY SYSTEMS </p>
            <p className="site-description">Outsource CAD Services</p>
          </div>
        </a>

        <div className="ast-site-title-wrap">
          <h1 className="site-title" >
            <Link to="/" rel="home"></Link>
          </h1>
        </div>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services/">Services</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/products/">Products</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/aboutus/">About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contactus/">Contact</Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header
